<template>
  <message v-if="message" :message="message" />
</template>

<script>
import Message from './Message.vue'
export default {
  components: { Message },
  name: 'MailRedirect',
  props: ['search'],
  mounted() {
    this.$store.dispatch('confirmRegistrationByMail', this.search).then(res => {
      this.message = res.message
      setTimeout(() => this.$router.push({ name: 'authorization' }), 3000)
    })
  },
  data() {
    return {
      message: '',
    }
  },
}
</script>

<style></style>
